@import "syntax-highlighting"

body
  color: #212121
  font-size: 13px
  font-family: HelveticaNeue,Helvetica,Arial,sans-serif

pre
  border: 0
  background-color: #272822
  color: white
  border-radius: 0

code
  font-family: 'Inconsolata', monospace
  border-radius: 0
  font-size: 100%

blockquote
  background-color: #ffff99
  font-size: inherit
  border-left: 0

h1, h2
  font-weight: 800

h1
  font-size: 180%

h2
  font-size: 170%

h3
  font-size: 120%

.sidebar-nav
  background-color: darken(#fafafa, 5%)
  padding-top: 15px
  padding-bottom: 15px
  strong
    font-size: 120%
  a
    color: #212121
  ul
    margin-bottom: 30px
  > ul
    padding-top: 5px

.resource
  strong
    font-weight: 600
  ul
    margin-left: 10px
    margin-bottom: 5px

.logo
  margin-top: 0
  height: 40px
  display: block

.main
  .sidebar-text
    background-color: white
    color: #212121
  .sidebar-code
    background-color: #272822
    color: white
    pre
      padding: 0
  > .row:last-child
    .sidebar-text, .sidebar-code
      padding-bottom: 100px

@media (min-width: 768px)
  .sidebar-nav
    height: 100%
    left: 0
    position: fixed
    top: 0
    display: block
    z-index: 3
    overflow-y: scroll
  .main
    padding-right: 0
    display: table
    > .row
      display: table-row
      > div[class*='col-']
        display: table-cell
        float: none

@media (max-width: 767px)
  .sidebar-code
    padding-top: 15px
    padding-bottom: 15px

#search-input
  height: 46px
.DocSearch
  --docsearch-modal-background: #F8F9FF
  --docsearch-searchbox-background: #FFFFFF
  --docsearch-text-color: #1F2933
  --docsearch-primary-color: #183BEE
  --docsearch-logo-color: #183BEE
  --docsearch-highlight-color: var(--docsearch-primary-color)
  --docsearch-searchbox-shadow: inset 0 0 0 1px var(--docsearch-primary-color)
  font-size: 16px
  mark
    padding: 0
  &.DocSearch-Button
    width: 100%
    margin: 0
    margin-bottom: 10px
  .DocSearch-Button-Placeholder
    font-size: 16px
